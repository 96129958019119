import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { ContactForm } from "../components/contactForm"
import CheckCircleIcon from "../images/check-circle-icon.svg";
import {Pricing} from "../components/pricing";
export const Section = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 40px 0;
  font-size: 18px;
   @media (max-width: 991px) {
    padding: 40px;
    
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  p { font-size: 20px; }
  svg {
    margin: 60px 0;
  }
`;

const ContactPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const images = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "contact-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      smallHeader={true}
      title={"PERSÖNLICHES ANGEBOT EINHOLEN"}
      image={images.header.childImageSharp.fluid}
    >
      <SEO
        title="Angebot einholen"
        description={
          "Kontaktieren Sie uns völlig unverbindlich und wir melden uns bei Ihnen zurück. Wir besprechen das System, Ihre individuellen Wünsche und finden eine für Ihre Bedürfnisse optimierte Lösung."
        }
      />
      <Section>
        {submitted && <>
          <SuccessWrapper>
            <CheckCircleIcon />
            <p>
              GRAZIE • THANKS • どうも • MERCI • DANKE
            </p>
            <p>
              Wir melden uns schnellstmöglich bei Ihnen!
            </p>
          </SuccessWrapper>
        </>}
        {!submitted && <><p>
          Wenn wir Ihr Interesse geweckt haben, oder Sie noch Fragen haben,
          trauen Sie sich ruhig.
        </p>
        <p>
          Kontaktieren Sie uns völlig unverbindlich und wir melden uns bei Ihnen
          zurück. Wir besprechen das Sytem, Ihre individuellen Wünsche und
          finden eine für Ihre Bedürfnisse optimierte Lösung. Sollten wir in
          keiner Form zueinander finden, haben Sie vielleicht trotzdem ein paar
          neue Kunden gewonnen und wir eine neue Location für die Mittagspause.
          :)
        </p>

          <Pricing />

        <ContactForm setSubmitted={setSubmitted} /></>}
      </Section>
    </Layout>
  )
}

export default ContactPage
